import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
// import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default function EditModal({ modalController, toggleModal, modalData, airlinesOptions, setData, unitsOptions, handleFormSubmit, loader }) {
    const [passengerOptions, setPassengerOptions] = useState([]);

    useEffect(() => {
        console.log(modalData);
        if (modalData.unit && unitsOptions.length > 0) {
            let maxCapacity = parseInt(unitsOptions.filter((unit) => unit.name === modalData.unit)[0].capacity);
            let capacityOptions = [];
            for (let i = 0; i < maxCapacity; i++) {
                capacityOptions.push(i + 1);
            };
            setPassengerOptions(capacityOptions);
        }

    }, [modalData]);

    const handleInputChange = (e) => {
        console.log(e.target.name, e.target.value);
        setData({
            ...modalData,
            [e.target.name]: e.target.value,
        });
    }
  

    const handleArrivalDate = (date) => {
        setData({
            ...modalData,
            arrival_datetime: date,
        });
    }

    const handleDepartureDate = (date) => {
        setData({
            ...modalData,
            departure_datetime: date,
        });
    }

    return (
        <Modal isOpen={modalController} size='lg' scrollable={false} className="modal-edit">
            <ModalHeader toggle={toggleModal}>
                RESERVATION ID: {modalData.folio}
            </ModalHeader>
            <form onSubmit={handleFormSubmit}>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col xs={12} sm={6}>
                                <h3>Traveler info</h3>
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <input type="text" className="form-control" value={modalData.fullname} name='fullname' onChange={handleInputChange} required />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" value={modalData.email} name='email' onChange={handleInputChange} required />
                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" className="form-control" value={modalData.cellphone} onChange={(e) => {
                                        if (!isNaN(e.target.value)) handleInputChange(e)
                                    }} required />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <h3>Transportation info</h3>
                                <div className="form-group">
                                    <label>Service Type:</label>
                                    <select className="form-control" value={modalData.trip_type} name='trip_type' onChange={handleInputChange} required>
                                        <option value='One Way'>One Way</option>
                                        <option value='Round Trip'>Round Trip</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Transport vehicle</label>
                                    <select className="form-control" value={modalData.unit} name='unit' onChange={handleInputChange} required>
                                        
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Passenger number</label>
                                    <select className="form-control" value={modalData.total_passengers} name='total_passengers' onChange={handleInputChange} required>
                                        {passengerOptions.length > 0 &&
                                            passengerOptions.map((item, index) => {
                                                return <option key={index} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12}>
                                <h3>Arrival information</h3>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className="form-group">
                                    <label>Pickup date:</label><br/>                                    
                                </div>
                            </Col>
                        </Row>
                        {modalData.trip_type && modalData.trip_type !== 'One Way' &&
                            <Row>
                                <Col xs={12}>
                                    <h3>Departure information</h3>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <div className="form-group">
                                        <label>Pickup date:</label><br/>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <div className="form-group">
                                        <label>Flight's number:</label>
                                        <input type="text" className="form-control" value={modalData.departure_flight_number} name='departure_flight_number' onChange={handleInputChange} required />
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label>Observations:</label>
                                    <textarea className="form-control" rows="3" value={modalData.observations} name='observations' onChange={handleInputChange} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' type='button' onClick={toggleModal}>Cancel</Button>
                    <Button color='primary' type='submit' >Accept</Button>
                </ModalFooter>
            </form>
        </Modal>
    )
}