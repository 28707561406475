import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import DataTable from 'react-data-table-component';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './ReservationsTable.module.scss'
const ReservationsTable = (props) => {
    const columns = [
        {
            name: "ID Reservation",
            selector: row => row.booking_id,
            sortable: true,
        },
        {
            name: "Created Date",
            selector: row => row.created_on ? new Date(row.created_on).toISOString().split('T')[0] : '',
            sortable: true,
        },
        {
            name: "Type Service",
            selector: row => row.type_service,
            sortable: true,
        },
        {
            name: "Client",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Arrival",
            selector: row => row.arrival_date ? moment(row.arrival_date).format('YYYY-MM-DD') : '',
            sortable: true,
        },
        {
            name: "Flight number",
            selector: row => row?.arrival_flight,
            sortable: true,
        },
        {
            name: "Departure",
            selector: row => row.departure_date ? moment(row.departure_date).format('YYYY-MM-DD'): '',
            sortable: true,
        },
        {
            name: "Transport",
            selector: row => row.service,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="d-flex">
                    <Button color='primary' onClick={() => props.handleSeeDetails(row)}>
                        <FontAwesomeIcon icon="info-circle" />
                    </Button>
                    {/* <Button color='secondary' onClick={() => props.handleEdit(row)}>
                        <FontAwesomeIcon icon="edit" />
                    </Button>
                    <Button color='warning' onClick={() => props.resendEmail(row)}>
                        <FontAwesomeIcon icon="paper-plane" />
                    </Button> */}
                </div>
            )
        }
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        button: {
            excel: false,
        }
    };

    const [records, setRecords] = useState(props.records);
    const [filteredRecords, setFilteredRecords] = useState(props.records);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setRecords(props.records);
        setFilteredRecords(props.records);
    }, [props.records]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearch(value);
        const filtered = records.filter(record => 
            Object.values(record).some(val => 
                String(val).toLowerCase().includes(value)
            )
        );
        setFilteredRecords(filtered);
    };

    const exportExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filteredRecords);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;' });
        FileSaver.saveAs(blob, 'reservations.xlsx');
    };

    return (
        <div className='table-responsive'>
            <div className="d-flex justify-content-between mb-2">
                <h2>Reservations</h2>
                <Button color='primary' onClick={exportExcel}>
                    <FontAwesomeIcon icon="file-excel" /> Export Excel
                </Button>
            </div>
            <div className={styles.input}>
                <Input 
                    type="text" 
                    placeholder="Search..." 
                    value={search} 
                    onChange={handleSearch} 
                    className="mb-3"
                />
            </div>
            <DataTable
                columns={columns}
                data={filteredRecords}
                pagination
                striped
                highlightOnHover
            />
        </div>
    );
};

export default ReservationsTable;
