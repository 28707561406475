import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import ReservationsTable from './ReservationsTable';
import { getAllReservationsByDateRange, updateReservation } from '../../controllers/reservations_controller';
import { sendMail, parserEmailData } from '../../controllers/email_controller';
import DetailsModal from './DetailsModal';
import EditModal from './EditModal';
import Swal from 'sweetalert2';

const getInitialQueryDates = () => {
    let queryEndDate = new Date();
    queryEndDate.setHours(23, 59, 59);

    let queryInitDate = new Date();
    queryInitDate.setMonth(queryInitDate.getMonth() - 1); // Un mes atrás
    queryInitDate.setHours(0, 0, 0);

    return {
        date_init: queryInitDate.toISOString().split('T')[0], // Formatear a YYYY-MM-DD
        date_end: queryEndDate.toISOString().split('T')[0],   // Formatear a YYYY-MM-DD
        filter: ''
    };
}

const Index = () => {
    const [reservationData, setReservationData] = useState([]);
    const [queryDates, setQueryDates] = useState(getInitialQueryDates);
    const [detailsModalController, setDetailsModalController] = useState(false);
    const [editModalController, setEditModalController] = useState(false);
    const [detailsModalData, setDetailsModalData] = useState({});
    const [editModalData, setEditModalData] = useState({});
    const [modalLoaderController, setModalLoaderController] = useState(false);
    const [refreshController, setRefreshController] = useState(false);
    const [filterDateRange, setFilterDateRange] = useState('');

    useEffect(() => {
        getAllReservationsByDateRange(queryDates).then((res) => {
            console.log(queryDates);
            let responseDataFormated = [];
            res.data.results.forEach((item) => {
                // item.id = item.id_retail;               
                // item.arrival_date = moment(item.arrival_date).format('LLL');
                // item.arrival_time = moment(item.arrival_time).toDate();

                // if (item.type_service !== 'One Way') {
                //     item.type_service = 'Round Trip';
                //     item.departure_date = moment(item.departure_date).format('LLL');
                //     item.departure_time = moment(item.departure_time).toDate();
                // } else {
                //     item.departure_date = '';
                //     item.departure_time = '';
                //     item.type_service = 'One Way';
                // }

                responseDataFormated.push(item);
            });
            setReservationData(responseDataFormated);
        }).catch((e) => {
            console.log(e);
        });
        setRefreshController(false);
    }, [queryDates, refreshController]);

    const handleDateRangeChange = (event, picker) => {        
        let dateInit= picker.startDate.toDate();
        let dateEnd= picker.endDate.toDate();
        console.log( moment(dateInit).format('YYYY-MM-DD HH:mm:ss'), moment(dateEnd).format('YYYY-MM-DD HH:mm:ss'));
        setQueryDates({
            date_init: moment(dateInit).format('YYYY-MM-DD HH:mm:ss'),
            date_end: moment(dateEnd).format('YYYY-MM-DD HH:mm:ss'),
            filter:filterDateRange,
        });
    }
    const handleDateRangeChangeFiltered = (event, picker) => {
        let dateInit= picker.startDate.toDate();
        let dateEnd= picker.endDate.toDate();
        console.log( moment(dateInit).format('YYYY-MM-DD'), moment(dateEnd).format('YYYY-MM-DD'));
        setQueryDates({
            date_init: moment(dateInit).format('YYYY-MM-DD'),
            date_end: moment(dateEnd).format('YYYY-MM-DD'),
            filter:filterDateRange,
        });
    }

    const handleSeeDetails = (data) => {
        setDetailsModalController(true);
        setDetailsModalData(data);
    }

    const handleEdit = (data) => {
        setEditModalController(true);
        setEditModalData(data);
    }

    const toggleDetailsModal = () => {
        setDetailsModalController(!detailsModalController);
        setDetailsModalData({});
    }

    const toggleEditModal = () => {
        setEditModalController(!editModalController);
        setEditModalData({});
    }

    const handleEditFormSubmit = (e) => {
        e.preventDefault();
        setModalLoaderController(true);
        let dataToSend = {
            folio: editModalData.folio,
            trip_type: editModalData.trip_type,
            unit: editModalData.unit,
            pickup_location: editModalData.pickup_location,
            destination_location: editModalData.destination_location,
            total_passengers: parseInt(editModalData.total_passengers),
            fullname: editModalData.fullname,
            email: editModalData.email,
            cellphone: editModalData.cellphone,
            arrival_datetime: moment(editModalData.arrival_datetime).format('YYYY-MM-DD hh:mm:ss a'),
            arrival_airline: editModalData.arrival_airline,
            arrival_flight_number: editModalData.arrival_flight_number,
            departure_datetime: moment(editModalData.departure_datetime).format('YYYY-MM-DD hh:mm:ss a'),
            departure_airline: editModalData.trip_type === 'Round Trip' ? editModalData.departure_airline : '',
            departure_flight_number: editModalData.trip_type === 'Round Trip' ? editModalData.departure_flight_number : '',
            observations: editModalData.observations,
            payment_method: editModalData.payment_method,
            discount_code: editModalData.discount_code,
            discount_percent: editModalData.discount_percent,
            total_payment: editModalData.total_payment
        };
        updateReservation(editModalData.id_reservation, dataToSend).then((res) => {
            console.log(res);
            setModalLoaderController(false);
            Swal.fire('Success!', 'The reservation has been updated.', 'success').then(() => {
                toggleEditModal();
                setRefreshController(true);
            })
        }).catch((e) => {
            console.log(e);
            setModalLoaderController(false);
            Swal.fire('Oops!', 'Something went wrong when updating the reservation...', 'error');
        })
    }

    const resendEmail = (e) => {

        Swal.fire({
            title: 'Are you sure you want to resent confirmation email?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept'
        }).then((result) => {
            if (result.isConfirmed) {

                let arrival_date_time = moment(e.arrival_datetime).format('YYYY-MM-DD hh:mm:ss a');
                e['arrival_datetime'] = arrival_date_time;

                if (e.departure_datetime !== '') {
                    let departure_datetime = moment(e.departure_datetime).format('YYYY-MM-DD hh:mm:ss a');
                    e['departure_datetime'] = departure_datetime;
                }

                //console.log('elegido', e)
                let email_data = parserEmailData(e);
                //console.log('parseado', email_data)
                sendMail(email_data).then(_response => {
                    Swal.fire('', 'The email was resend', 'success');
                });
            }
        })
    }

    const handleFilterDateRange = (filter) => {
        setFilterDateRange(filter)
    }

    return (
        <Fragment>
            <Container>
                <Row className="py-3">
                    <Col xs={12} md={6} className="d-flex align-items-end">
                        <h1>REGISTERED RESERVATIONS</h1>
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-between justify-content-md-end align-items-end gap-2">
                        <Button color={filterDateRange === 'arrival' ? 'primary' : 'secondary'} onClick={() => handleFilterDateRange('arrival')}>Set Arrival Dates</Button>
                        <Button color={filterDateRange === 'departure' ? 'primary' : 'secondary'} onClick={() => handleFilterDateRange('departure')}>Set Departure Dates</Button>
                        <div className="datepicker-range">
                            <small>Set Date Reservation by {filterDateRange ? <b>{filterDateRange.toUpperCase()}</b> : 'Created Date'}:</small>
                            <DateRangePicker onApply={handleDateRangeChange}>
                                <input type="text" className="form-control input-range" />
                            </DateRangePicker>
                        </div>
                    </Col>
                    <Col xs={12} className="my-4">
                        <ReservationsTable
                            records={reservationData}
                            handleSeeDetails={handleSeeDetails}
                            handleEdit={handleEdit}
                            resendEmail={resendEmail} />
                    </Col>
                </Row>
            </Container>
            <DetailsModal modalController={detailsModalController} toggleModal={toggleDetailsModal} modalData={detailsModalData} />
            <EditModal modalController={editModalController} toggleModal={toggleEditModal} modalData={editModalData}
                setData={setEditModalData} handleFormSubmit={handleEditFormSubmit} loader={modalLoaderController} />
        </Fragment>
    )
}

export default Index


