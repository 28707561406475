import React from 'react';
import { CardImg, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';

export default function DetailsModal({ modalController, toggleModal, modalData }) {
    console.log(modalData)
    return (
        <Modal isOpen={modalController} size='lg' scrollable={false}>
            <ModalHeader toggle={toggleModal}>
                RESERVATION ID: {modalData.booking_id}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={8} className='my-3'>
                        <h3>{`TOTAL: $${modalData.total_fare}`.toUpperCase()} USD</h3>
                        departure_to
                        <p><b>Service:</b> {modalData.service}</p>
                        <p><b>Trip type:</b> {modalData.type_service}</p>
                        <p><b>Passengers:</b> {modalData.total_passengers}</p>
                        <p><b>Transport selected:</b> {modalData.service}</p>
                        <p><b>Pick-up location:</b> {modalData.arrival_from}</p>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} className='my-3'>
                        <h4>Contact/Traveler information: </h4>
                        <p><b>Full name:</b> {modalData.name} {modalData.lastname}</p>
                        <p><b>Phone:</b> {modalData.phone}</p>
                        <p><b>E-mail:</b> {modalData.email}</p>
                    </Col>
                    {modalData.comments && (
                        <Col xs={6} className='my-3'>
                            <p>Comments:</p>
                            <p>{modalData.comments}</p>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs={6} className='my-3'>
                        <hr />
                        <h4>Pickup information: </h4>
                        <p><b>Arrival to:</b> {modalData.arrival_to}</p>
                        <p><b>Date/Time:</b> {modalData.arrival_date} - {modalData.arrival_time}</p>
                        <p><b>Flight number</b> {modalData.arrival_flight}</p>
                        <p><b>Airline Name</b> {modalData.arrival_airline}</p>
                    </Col>
                    {modalData.type_service !== 'One Way' &&
                        <Col xs={6} className='my-3'>
                            <hr />
                            <h4>Departure information: </h4>
                            <p><b>Departure to:</b> {modalData.departure_to}</p>
                            <p><b>Date/Time:</b> {modalData.departure_date} - {modalData.departure_time}</p>
                            <p><b>Flight number</b> {modalData.departure_flight}</p>
                            <p><b>Airline Name</b> {modalData.departure_airline}</p>
                        </Col>
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={toggleModal}>Close</Button>
                {/* <Button color='primary' onClick={toggleModal}>Accept</Button> */}
            </ModalFooter>
        </Modal>
    )
}

