import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { getAllUsers, createNewUser, deleteUser, editUser } from '../../controllers/user_controller';
import Swal from 'sweetalert2';
// import UsersTable from './UsersTable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Index = () => {
    const [usersData, setUsersData] = useState([]);
    const [refreshController, setRefreshController] = useState(false);
    const [loaderController, setLoaderController] = useState(false);
    const [modalController, setModalController] = useState(false);
    const [editController, setEditController] = useState(false);
    const [formData, setFormData] = useState({
        id_rol: 1, fullname: '', email: '', password: '', phone: '', id_user: '', rep_password: ''
    });

    useEffect(() => {
        getAllUsers().then((response) => {
            let responseDataFormated = []
            response.data.results.forEach((item, index) => {
                item.id = item.id_user;
                responseDataFormated.push(item);
            });
            setUsersData(responseDataFormated);
        }).catch((e) => {
            console.log(e);
        });
        setRefreshController(false);
    }, [refreshController]);

    const openModal = () => {
        setModalController(true);
        setEditController(false);
        setFormData({ id_rol: 1, fullname: '', email: '', password: '', phone: '', id_user: '', rep_password: '' });
    }

    const toggleModal = () => {
        setModalController(!modalController);
        setFormData({ id_rol: 1, fullname: '', email: '', password: '', phone: '', id_user: '', rep_password: '' });
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleFormSubmitCreate = (e) => {
        e.preventDefault();
        if (formData.password !== formData.rep_password) {
            Swal.fire('Error', 'Passwords do not match.', 'error');
        } else {
            setLoaderController(true);
            let dataToSend = {
                id_rol: formData.id_rol,
                fullname: formData.fullname,
                email: formData.email,
                password: formData.password,
                phone: formData.phone,
            };
            createNewUser(dataToSend).then((response) => {
                setFormData({ id_rol: 1, fullname: '', email: '', password: '', phone: '', id_user: '', rep_password: '' });
                setLoaderController(false)
                Swal.fire('Success!', 'The user has been created successfully.', 'success').then(() => {
                    toggleModal();
                    setRefreshController(true);
                });
            }).catch((e) => {
                console.log(e);
                Swal.fire('Oops!', 'Something went wrong when creating new user', 'error');
                setLoaderController(false);
            })
        }
    }

    const handleFormSubmitEdit = (e) => {
        e.preventDefault();
        if (formData.password === '' && formData.rep_password === '') {
            setLoaderController(true);
            let dataToSend = {
                id_rol: formData.id_rol,
                fullname: formData.fullname,
                email: formData.email,
                phone: formData.phone,
            };
            editUser(formData.id_user, dataToSend).then((res) => {
                setFormData({ id_rol: 1, fullname: '', email: '', password: '', phone: '', id_user: '', rep_password: '' });
                setLoaderController(false)
                Swal.fire('Success!', 'The user has been updated successfully.', 'success').then(() => {
                    toggleModal();
                    setRefreshController(true);
                });
            }).catch((e) => {
                console.log(e);
                Swal.fire('Oops!', 'Something went wrong when updating user', 'error');
                setLoaderController(false);
            });
        } else {
            if (formData.password !== formData.rep_password) {
                Swal.fire('Error', 'Passwords do not match.', 'error');
            } else {
                setLoaderController(true);
                let dataToSend = {
                    id_rol: formData.id_rol,
                    fullname: formData.fullname,
                    email: formData.email,
                    password: formData.password,
                    phone: formData.phone,
                };
                editUser(formData.id_user, dataToSend).then((response) => {
                    setFormData({ id_rol: 1, fullname: '', email: '', password: '', phone: '', id_user: '', rep_password: '' });
                    setLoaderController(false)
                    Swal.fire('Success!', 'The user has been updated successfully.', 'success').then(() => {
                        toggleModal();
                        setRefreshController(true);
                    });
                }).catch((e) => {
                    console.log(e);
                    Swal.fire('Oops!', 'Something went wrong when updating user', 'error');
                    setLoaderController(false);
                });
            }
        }
    }

    const handleEditUser = (data) => {
        setEditController(true);
        setFormData({
            id_rol: data.id_rol,
            fullname: data.fullname,
            email: data.email,
            password: '',
            phone: data.phone,
            id_user: data.id_user,
            rep_password: ''
        });
        setModalController(true);
    }

    const handleDeleteUser = (userData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "The user will be deleted!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(userData.id_user).then(() => {
                    Swal.fire('Success!', 'The user has been deleted successfully.', 'success').then(() => {
                        setRefreshController(true);
                    });
                }).catch((e) => {
                    Swal.fire('Oops!', 'Something went wrong when deleting the selected user', 'error');
                });
            }
        })
    }

    return (
        <Fragment>
            <Container>
                <Row className="py-3">
                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                        <h1>USERS</h1>
                        <Button color="primary" onClick={openModal}>
                            <FontAwesomeIcon className="mr-2" icon="plus-circle" />
                            Add User
                        </Button>
                    </Col>
                    {/* <Col xs={12} className="my-4">
                        <UsersTable records={usersData} deleteHandler={handleDeleteUser} editHandler={handleEditUser} />
                    </Col> */}
                </Row>
            </Container>
            <Modal isOpen={modalController} size='xl' scrollable={false} >
                <ModalHeader toggle={toggleModal}>
                    {
                        editController ? 'Edit user' : 'Add new user'
                    }
                </ModalHeader>
                <form onSubmit={editController ? handleFormSubmitEdit : handleFormSubmitCreate}>
                    <ModalBody>
                        <Row>
                            <Col xs={6} className='my-3'>
                                <Label>Full Name</Label>
                                <input className='form-control' type='text' placeholder='Full name' name='fullname'
                                    onChange={handleInputChange} value={formData.fullname} required />
                            </Col>
                            <Col xs={6} className='my-3'>
                                <Label>E-mail address</Label>
                                <input className='form-control' type='email' placeholder='E-mail' name='email'
                                    onChange={handleInputChange} value={formData.email} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className='my-3'>
                                <Label>Phone number</Label>
                                <input className='form-control' type='text' placeholder='Phone' name='phone'
                                    value={formData.phone} required
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value)) {
                                            handleInputChange(e);
                                        }
                                    }} />
                            </Col>
                            <Col xs={6} className='my-3'>
                                <Label>Rol</Label>
                                <select className='form-control' name='id_rol'
                                    onChange={handleInputChange} value={formData.id_rol} required>
                                    <option value={1}>Admin</option>
                                    <option value={2}>Employee</option>
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className='my-3'>
                                <Label>{editController ? 'New password' : 'Password'}</Label>
                                <input className='form-control' type='password' placeholder='Password' name='password'
                                    onChange={handleInputChange} value={formData.password} required={!editController} />
                            </Col>
                            <Col xs={6} className='my-3'>
                                <Label>Repeat {editController ? 'new' : ''} password</Label>
                                <input className='form-control' type='password' placeholder='Repeat password' name='rep_password'
                                    onChange={handleInputChange} value={formData.rep_password} required={!editController} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" type='button' onClick={toggleModal}>Cancel</Button>
                        <Button color="primary" type='submit'>{editController ? 'Edit' : 'Add'}</Button>
                    </ModalFooter>
                </form>
            </Modal>
        </Fragment>
    )
}

export default Index;